import ImageAvatar from "./assets/images/img-app.png";
import Icon1 from "./assets/images/icon-1.png";
import Icon2 from "./assets/images/icon-2.png";
import ImageMain from "./assets/images/img-app.png";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <header className="page-header">
        <div className="container wrapper">
          <div className="img-avatar">
            <img src={ImageAvatar} alt="" />
          </div>
          <ul className="socials">
            <li>
              <a
                href="https://t.me/KAPICOINSOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Icon1} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/KAPICOINSOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Icon2} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </header>
      <main className="page-main">
        <div className="container wrapper">
          <h3 className="page-title">$KAPICOIN</h3>
          {/* <p className="des"></p> */}
          <div className="img-main">
            <img src={ImageMain} alt="" />
          </div>
          <ul className="tools">
            <a
              href="https://mevx.io/solana/C5TnqzUGNiW3Wnt9Mt1H2KSAVTmjc7D7uMip65Mpump"
              target="_blank"
              rel="noopener noreferrer"
            >
              MevX.io
            </a>
            <a
              href="https://dexscreener.com/solana/3dyHrvkK3RPvJ2P3bzDXkbhenYmqjNrMafkMM5TfFba3"
              target="_blank"
              rel="noopener noreferrer"
            >
              DexScreener
            </a>
          </ul>
          <a href="" className="btn-buy">
            buy now!
          </a>
          <p className="address">
            CA: C5TnqzUGNiW3Wnt9Mt1H2KSAVTmjc7D7uMip65Mpump
          </p>
        </div>
      </main>
      <footer className="page-footer">
        <div className="container wrapper">
          <p>KAPICOIN 2024 | All Right Reserved</p>
          {/* <p>Contact:@GMAIL.COM</p> */}
        </div>
      </footer>
    </div>
  );
}

export default App;
